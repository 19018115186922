import React from "react"
import { graphql } from "gatsby"

import ServiceSection from "../../components/Services/ServiceSection"
import ServiceLayout from "../../components/Services/ServiceLayout"
import CTA from "../../components/CTA"
import Grid from "../../components/grid/Grid"
import SEO from "../../components/SEO"

const Physiotherapy = ({ data }) => {
  const contentA = (
    <>
      <p>
        Whether you’re experiencing pain, tightness or limited range of motion,
        we’ll create a customized treatment plan to relieve discomfort and
        improve your range of motion.
      </p>
      <p>
        Art + Science takes a collaborative approach to treatment. Our
        physiotherapist, clinical pilates instructor and registered massage
        therapist share insights and details about your assessment to offer a
        blend of treatments and techniques designed specifically for you.{" "}
      </p>
      <p>
        We provide manual therapy to help with a wide range of injuries and
        conditions. Our focus is not just to remediate pain but also to provide
        preventative strategies to help optimize your mobility.
      </p>
      <p>
        It’s essential that you feel heard and understood, which is why our
        physiotherapist works one-on-one with you for the duration of your
        session.
      </p>
      <p>
        For more complex injuries, multiple areas of pain or for those requiring
        longer treatment time, we recommend booking an extended physiotherapy
        session.
      </p>
    </>
  )

  const contentB = (
    <ul>
      <li>Joint, muscle, and nerve pain</li>
      <li>Prenatal or postpartum issues</li>
      <li>Traumatic injuries</li>
      <li>Repetitive strain injuries</li>
      <li>Decreased mobility</li>
      <li>Balance difficulties and falls</li>
      <li>Pre- or post-surgical rehabilitation</li>
      <li>Neuromuscular impairments</li>
    </ul>
  )

  const physioBlurbData = [
    {
      title: "Manual therapy",
      text: "Hands-on techniques for stiff joints that help them move more freely, restore flexibility and relieve pain.",
    },
    {
      title: "Therapeutic movement",
      text: "gentle exercises that help relieve pain, build strength and move the brain and body in harmony from head to toe.",
    },
    {
      title: "Clinical Pilates-based strengthening exercises",
      text: "Build core strength, flexibility, and optimize alignment and posture using the principles of Stott Pilates.",
    },
    {
      title: "Active release and soft tissue techniques",
      text: "Relieve tension in your muscles and fascial system through hands-on massage.",
    },
    {
      title: "Therapeutic taping",
      text: "Support injured tissue with tape to encourage the brain to control the movement of joints after an injury.",
    },
    {
      title: "Acupuncture",
      text: "An ancient Chinese medicine that helps to restore blood flow and decrease swelling and pain. Fine needles are inserted into specific points to help calm the nervous system and kickstart the healing process.",
    },
    {
      title: "Dry needling",
      text: "Fine needles are inserted into tense or injured muscles to relieve pain and tension and promote healing.",
    },
  ]

  const contentC = (
    <div className="grid gap-y-6 md:gap-y-8">
      {physioBlurbData.map((blurb, index) => (
        <div key={`physioBlurb-${index}`}>
          <h4>{blurb.title}</h4>
          <p>{blurb.text}</p>
        </div>
      ))}
    </div>
  )

  return (
    <ServiceLayout serviceTitle="Physiotherapy">
      <SEO title="Physiotherapy" />
      <ServiceSection
        content={contentA}
        image={data.photoOne}
        subTitle="Putting you on a path to better movement"
      />
      <ServiceSection
        subTitle="Our physiotherapy services cover a wide range of conditions, including:"
        content={contentB}
        optionB
        image={data.photoTwo}
      />
      {/* <ServiceSection
        subTitle="Our qualified team of therapist use a blend of proven physiotherapy techniques"
        content={contentC}
        optionC
      /> */}
      <Grid className="col-span-full">
        <div
          className={` grid gap-y-5 col-span-full md:col-start-2 md:col-end-12 relative`}
        >
          <h2>
            Our qualified team of therapists use a blend of evidence-based
            physiotherapy techniques
          </h2>
          <div className="grid gap-y-6 md:gap-y-8 md:grid-cols-2 gap-x-20 mt-3 md:mt-8">
            {physioBlurbData.map((blurb, index) => (
              <div key={`physioBlurb-${index}`} className="grid gap-y-2">
                <h4>{blurb.title}</h4>
                <p>{blurb.text}</p>
              </div>
            ))}
          </div>
        </div>
      </Grid>
      <CTA
        title="Wondering if physiotherapy is right for you?"
        text="We offer complimentary consultations. We want to sit down with you to discuss your concerns face to face. You have our undivided attention."
        to="https://artandsciencephysio.janeapp.com/#/staff_member/1/treatment/8"
      />
    </ServiceLayout>
  )
}

export const query = graphql`
  query PhysioQuery {
    photoOne: file(relativePath: { eq: "2022/007A5995.jpg" }) {
      relativePath
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 75, width: 650)
      }
    }
    photoTwo: file(relativePath: { eq: "2022/AD8Q4500.jpg" }) {
      relativePath
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 75, width: 650)
      }
    }
  }
`

export default Physiotherapy
